/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import type {KitAdminState} from '@dv/kitadmin/models';
import {KinderOrt} from '@dv/kitadmin/models';
import {PERMISSION} from '@dv/shared/authentication/model';
import type {RequiredPermission} from '@dv/shared/code';

export const KIND_ABWEICHUNGS_MELDUNGEN = {
    name: 'base.kinderort.kind-abweichungs-meldungen',
    url: '/kind-abweichungs-meldungen',
    data: {
        subtitle: 'KINDERORT.NAV_KIND_ABWEICHUNGS_MELDUNGEN',
        permission(toParams: { id: string }): RequiredPermission {
            return [
                `${PERMISSION.MODULE.GROUP_ADMIN}:${toParams.id}`,
                {
                    oneOf: [
                        `${PERMISSION.KITA.CONTROL}${toParams.id}`,
                        `${PERMISSION.KITA.MODIFY_EXTRA_PLATZ}${toParams.id}`,
                    ],
                },
            ];
        },
    },
    paramDeclaration: {
        kinderOrt: KinderOrt,
    },
} satisfies KitAdminState;
